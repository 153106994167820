import { Route, Routes } from "react-router-dom";

import Auth from "./pages/Auth/Auth";
import AuthRoutes from "./components/HOC/AuthRoutes";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import { auth } from "./firebase";
import { login } from "./features/authSlice";
import { useAppDispatch } from "./hooks/storeHook";
import { useEffect } from "react";

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && user.email)
        dispatch(
          login({
            email: user.email,
            id: user.uid,
          })
        );
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Routes>
      <Route element={<AuthRoutes />}>
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="/" element={<Home />} />
      <Route path="auth" element={<Auth />} />
    </Routes>
  );
};

export default App;
