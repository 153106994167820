export const authClasses = {
    container:
      "grid h-screen w-screen place-items-center px-4 text-sm font-medium",
    form: "p-4 md:p-5 lg:p-6",
    button:
      "flex items-center justify-center gap-x-2 rounded-md border border-slate-600 bg-slate-700 py-3 px-4 text-white transition hover:text-red-400",
    buttonSmall:
      "flex items-center justify-center gap-x-2 rounded-md border border-slate-600 bg-slate-700 py-1 px-2 text-white transition hover:text-red-400 my-2",
    input:
      "focus:border-purple-400 w-full rounded-md border border-slate-600 bg-slate-700 py-3 px-4 text-slate-200 outline-none transition placeholder:text-slate-400 required:border-accent-color-1",
    text: "text-slate-500",
    textLight: "text-slate-300",
    link: "font-medium cursor-pointer text-accent-color-2 underline dark:text-primary-500",
    hr: "w-full border-slate-600",
    forgotPasswordButton: "mx-3 w-full text-slate-500",
    disabledButton:
      "flex items-center justify-center gap-x-2 rounded-md bg-gray-700 py-3 px-4",
    
  };