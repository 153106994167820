import React from "react";
import { authClasses } from "../../../pages/Auth/authClasses";
import med from "../../../docs/MedicalRelease.pdf";

const RegistrationSteps = () => {
  const { link } = authClasses;
  return (
    <div style={{ marginBottom: "16px" }}>
      <h5>Registration Steps:</h5>
      <ol className="list-decimal mx-4">
        <li>
          Select events to register for. Status will show{" "}
          <span style={{ color: "yellow" }}>"Ready for Payment"</span>.
        </li>
        <li>
          Pay for events. Status will show{" "}
          <span style={{ color: "green" }}>"Registration Complete"</span>
        </li>
        <h6>Registering for tryouts? Extra Steps:</h6>
        <li>
          Print out and bring a <strong>physical copy</strong> of the{" "}
          <a
            href={med}
            target="_blank"
            rel="noopener noreferrer"
            className={link}
          >
            Medical Release
          </a>{" "}
          form to tryouts.{" "}
          <u>
            Athletes cannot tryout without a physical copy of the medical
            release form.
          </u>
        </li>
        <li>
          At least one parent or guardian must attend tryouts from start to
          finish to sign the player/parent contract at the end of tryouts (if
          selected to a team).
        </li>
      </ol>
    </div>
  );
};

export default RegistrationSteps;
