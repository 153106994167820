import { FC, useState } from "react";

import Athlete from "./Athlete";
import { DocumentData } from "firebase/firestore";
import RegisterAthlete from "../RegisterAthlete";
import RegistrationSteps from "./RegistrationSteps";
import { User } from "../../../models/User";
import { authClasses } from "../../../pages/Auth/authClasses";

type AthletesProps = {
  user: User;
  fetchData: () => {};
  fetchAthletes: () => {};
  athletes: any[];
  data: any;
  events: DocumentData[];
  addEventRegistration: (
    eventID: string,
    athleteID: string,
    regID: string
  ) => void;
};

const Athletes: FC<AthletesProps> = (props) => {
  const { user, fetchAthletes, athletes, events, addEventRegistration } = props;

  const { button, link } = authClasses;

  const [registering, setRegistering] = useState(false);

  return (
    <div className="grid">
      {registering ? (
        <RegisterAthlete
          user={user}
          fetchAthletes={fetchAthletes}
          setRegistering={setRegistering}
        />
      ) : athletes.length > 0 ? (
        <div className="grid p-4">
          <RegistrationSteps />
          <hr />
          <div className="text-sm text-left">
            <h6 style={{ margin: "16px 0" }}>Athletes:</h6>
            {athletes.map((athlete) => {
              return (
                <Athlete
                  athlete={athlete}
                  key={athlete.firstName}
                  user={user}
                  fetchAthletes={fetchAthletes}
                  events={events}
                  addEventRegistration={addEventRegistration}
                />
              );
            })}
          </div>
          <button className={button} onClick={() => setRegistering(true)}>
            {" "}
            + Add another athlete
          </button>
        </div>
      ) : (
        <div
          style={{
            marginLeft: "1rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <RegistrationSteps />
          <button className={button} onClick={() => setRegistering(true)}>
            {" "}
            + Add an athlete
          </button>
        </div>
      )}
      <p style={{ textAlign: "center", marginBottom: "16px" }}>
        Send an email to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={link}
          href="mailto:director@sidewinderva.com"
        >
          director@sidewinderva.com
        </a>{" "}
        if you have any issues or questions.
      </p>
    </div>
  );
};

export default Athletes;
