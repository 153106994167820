import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import Event from "./Event";
import React from "react";

const RegisteredEvents = ({ athlete, events, setPayEvent }) => {
  const theseEvents =
    athlete.registrations === undefined
      ? []
      : events.filter(
          (e) => athlete.registrations.find((a) => a.id === e.id) !== undefined
        );
  return (
    <>
      {theseEvents.length === 0 ? (
        <small style={{ color: "white" }}>
          Select one of the options below to register
        </small>
      ) : (
        <div
          style={{
            backgroundColor: "#1c1d21",
            borderRadius: "8px",
            marginTop: "8px",
            padding: "4px 0",
          }}
        >
          <Accordion>
            <AccordionItem
              initialEntered
              header={
                <h6 style={{ margin: "8px" }}>
                  Registered ({theseEvents.length})
                </h6>
              }
            >
              {theseEvents.map((evnt) => {
                return (
                  <Event
                    evnt={evnt}
                    setPayEvent={setPayEvent}
                    registration={athlete.registrations.find(
                      (e) => e.id === evnt.id
                    )}
                    key={evnt.id}
                  />
                );
              })}
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default RegisteredEvents;
