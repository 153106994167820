import React, { useState } from "react";

import { authClasses } from "../../../pages/Auth/authClasses";
import axios from "axios";

const PayPopup = ({ athlete, evnt, setPayEvent }) => {
  const { buttonSmall } = authClasses;

  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);
  const [error, setError] = useState(null);

  const handlePay = async () => {
    setLoading(true);

    const cancelUrl = "http://dashboard.sidewinderva.com/";
    const successUrl = "http://dashboard.sidewinderva.com/";
    const lineItems = [
      {
        data: {
          price_data:
            evnt.type === "Tryout"
              ? {
                  product: "prod_PrUSSBd7SjrtWc",
                  unit_amount: 2606,
                }
              : evnt.cost === "90"
              ? {
                  product: "prod_PpwMfMnIwSujlM",
                  unit_amount: 9300,
                }
              : { product: "prod_Q9GV5nWeK3fDqP", unit_amount: 12389 },
          quantity: 1,
        },
      },
    ];

    const regID = athlete.registrations.find((a) => a.id === evnt.id).regID;

    console.log(athlete.firstName);
    try {
      const response = await axios.post(
        `https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/payregistration?cancelUrl=${cancelUrl}&successUrl=${successUrl}&regID=${regID}`,
        lineItems,
        {
          headers: {},
        }
      );
      console.log(response.data);
      window.location.href = response.data.url;
      setLoading(false);
      setPaying(true);
      return response.data.url;
    } catch (error) {
      console.log(error);
      return [];
    }
    // const colRef = collection(db, "registrations");

    // addDoc(colRef, { athlete: athlete.id, event: evnt.id, status: "unpaid" })
    //   .then((ref) => {
    //     setLoading(false);
    //     setPayEvent(null);
    //     addEventRegistration(evnt.id, athlete.id, ref.id);
    //   })
    //   .catch((err) => {
    //     setError(err);
    //   });
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
      }}
    >
      <div
        style={{
          width: "70vw",
          backgroundColor: "rgba(0,0,0,0.9)",
          display: "flex",
          borderRadius: "16px",
          padding: "16px",
          flexDirection: "column",
        }}
      >
        {error ? (
          <div>
            <h4 style={{ margin: "4px 0" }}>Error Paying for Event</h4>
            <h6 style={{ margin: "4px 0" }}>
              Email director@sidewinderva.com if you keep gettting this issue.
            </h6>
            <small>{error}</small>
            <button
              className={buttonSmall}
              onClick={() => {
                setError(null);
                setPayEvent(null);
              }}
            >
              Ok
            </button>
          </div>
        ) : paying ? (
          <div>
            <h4 style={{ margin: "4px 0" }}>Payment started</h4>
            <small style={{ margin: "4px 0", color: "white" }}>
              Refresh this page a few minutes after paying to see registration
              marked as "Paid".
            </small>
            <button
              className={buttonSmall}
              onClick={() => {
                setError(null);
                setPayEvent(null);
                setPaying(false);
                setLoading(false);
              }}
            >
              Ok
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5 style={{ margin: "8px 0" }}>
              Pay {evnt.type === "tryout" ? "Tryout" : "Event"} Fee
            </h5>
            <small>
              Athlete:{" "}
              <strong>
                {athlete.firstName} {athlete.lastName}
              </strong>
            </small>
            <small>
              Event:{" "}
              <strong>
                {evnt.title} - {evnt.dateString}
              </strong>
            </small>
            <p style={{ margin: "4px 0" }}>
              You can pay for this event with Zelle (send to:
              director@SidewinderVA.com, put athlete's name in the description),
              or click below to pay by card (3% fee added)
            </p>
            <p style={{ margin: "4px 0" }}>
              If you paid by Zelle, please allow some time for an admin to mark
              this event as "paid".
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "8px 0",
              }}
            >
              <button
                className={buttonSmall}
                style={{
                  backgroundColor: "gray",
                  opacity: loading ? 0.2 : 1,
                }}
                disabled={loading}
                onClick={() => setPayEvent(null)}
              >
                Cancel
              </button>
              <button
                className={buttonSmall}
                style={{
                  backgroundColor: "rgb(50, 150, 50)",
                  opacity: loading ? 0.2 : 1,
                }}
                onClick={() => handlePay()}
                disabled={loading}
              >
                {loading ? "Loading..." : "Pay Now"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayPopup;
