import { Dispatch, FC, SetStateAction } from "react";

import { authClasses } from "../../pages/Auth/authClasses";

type DropProps = {
  value: string;
  options: string[];
  style?: object;
  setVal: Dispatch<SetStateAction<string>>;
};

const FormDropdown: FC<DropProps> = (props) => {
  const { value, options, setVal, style } = props;
  const { input } = authClasses;
  return (
    <select
      value={value}
      onChange={(e) => setVal(e.target.value)}
      required
      className={input}
      style={style}
    >
      {options.map((option: string) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default FormDropdown;
