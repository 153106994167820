import FormDropdown from "../Dropdown";

const years = (title, offset) => {
  let currentYear = new Date().getFullYear() + offset;
  let years = [title];
  for (let i = 0; i < 15; i++) {
    years.push(currentYear++);
  }
  return years;
};

export const GradYears = years("HS Grad Year", 0);
const birthYears = years("Year", -19);

export const DOBSelect = ({ month, setMonth, day, setDay, year, setYear }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div style={{ marginRight: "8px" }}>
        <FormDropdown
          options={[
            "Month",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ]}
          style={{
            flex: 6,
            borderColor: month === "Month" ? "" : "green",
          }}
          value={month}
          setVal={setMonth}
        />
      </div>
      <div style={{ marginRight: "8px" }}>
        <FormDropdown
          options={[
            "Day",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
          ]}
          style={{ flex: 3, borderColor: isNaN(parseInt(day)) ? "" : "green" }}
          value={day}
          setVal={setDay}
        />
      </div>
      <div>
        <FormDropdown
          options={birthYears}
          style={{ flex: 4, borderColor: isNaN(parseInt(year)) ? "" : "green" }}
          value={year}
          setVal={setYear}
        />
      </div>
    </div>
  );
};

export const Height = ({ feet, setFeet, inches, setInches }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "8px" }}>
        <FormDropdown
          options={["Height (ft)", "4", "5", "6", "7"]}
          value={feet}
          setVal={setFeet}
          style={isNaN(parseInt(feet)) ? {} : { borderColor: "green" }}
        />
      </div>
      <div>
        <FormDropdown
          options={[
            "Height (in)",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
          ]}
          value={inches}
          setVal={setInches}
          style={isNaN(parseInt(inches)) ? {} : { borderColor: "green" }}
        />
      </div>
    </div>
  );
};

export const SchoolOptions = [
  { value: "Wrightstown Ridge", label: "Wrightstown Ridge" },
  { value: "Wilson", label: "Wilson" },
  { value: "Walden Grove", label: "Walden Grove" },
  { value: "Veritas Academy", label: "Veritas Academy" },
  { value: "Valencia", label: "Valencia" },
  { value: "Vail Innovation Center", label: "Vail Innovation Center" },
  { value: "Vail Academy", label: "Vail Academy" },
  { value: "Utterback", label: "Utterback" },
  { value: "University", label: "University" },
  { value: "Twin Peaks", label: "Twin Peaks" },
  { value: "Tucson", label: "Tucson" },
  { value: "Tucson Waldorf", label: "Tucson Waldorf" },
  { value: "Tucson Hebrew Academy", label: "Tucson Hebrew Academy" },
  { value: "Tortolita", label: "Tortolita" },
  { value: "The Gregory School", label: "The Gregory School" },
  { value: "Tanque Verde", label: "Tanque Verde" },
  { value: "Sunnyside", label: "Sunnyside" },
  { value: "Star Academic", label: "Star Academic" },
  { value: "St. Michael's", label: "St. Michael's" },
  { value: "St. Joseph", label: "St. Joseph" },
  { value: "St. John The Evangelist", label: "St. John The Evangelist" },
  { value: "St. Cyril", label: "St. Cyril" },
  { value: "St. Augustine", label: "St. Augustine" },
  { value: "St. Ambrose", label: "St. Ambrose" },
  { value: "Sierra", label: "Sierra" },
  { value: "Sentinel Peak", label: "Sentinel Peak" },
  { value: "Secrist", label: "Secrist" },
  { value: "Santa Rita", label: "Santa Rita" },
  { value: "Salpointe", label: "Salpointe" },
  { value: "Sahuaro", label: "Sahuaro" },
  { value: "Sabino", label: "Sabino" },
  { value: "Roskruge", label: "Roskruge" },
  { value: "Robins", label: "Robins" },
  { value: "Roberts-Naylor", label: "Roberts-Naylor" },
  { value: "Rincon", label: "Rincon" },
  { value: "Rincon Vista", label: "Rincon Vista" },
  { value: "Pusch Ridge", label: "Pusch Ridge" },
  { value: "Pueblo", label: "Pueblo" },
  { value: "Pueblo Gardens", label: "Pueblo Gardens" },
  { value: "Pistor", label: "Pistor" },
  { value: "Palo Verde", label: "Palo Verde" },
  { value: "Our Mother of Sorrows", label: "Our Mother of Sorrows" },
  { value: "Orange Grove", label: "Orange Grove" },
  { value: "Old Vail", label: "Old Vail" },
  { value: "Mountain View", label: "Mountain View" },
  { value: "Morgan Maxwell", label: "Morgan Maxwell" },
  { value: "Miles Exploratory", label: "Miles Exploratory" },
  { value: "Mica Mountain", label: "Mica Mountain" },
  { value: "Mary Meredith", label: "Mary Meredith" },
  { value: "Mary Belle McCorkle", label: "Mary Belle McCorkle" },
  { value: "Marana", label: "Marana" },
  { value: "Marana Middle", label: "Marana Middle" },
  { value: "Mansfeld Middle", label: "Mansfeld Middle" },
  { value: "Magee", label: "Magee" },
  { value: "Leman", label: "Leman" },
  { value: "Lawrence", label: "Lawrence" },
  { value: "Lauffer", label: "Lauffer" },
  { value: "La Cima", label: "La Cima" },
  { value: "Ironwood Ridge", label: "Ironwood Ridge" },
  { value: "Imago Dei", label: "Imago Dei" },
  { value: "Homeschool", label: "Homeschool" },
  { value: "Hollinger", label: "Hollinger" },
  { value: "Gridley", label: "Gridley" },
  { value: "Flowing Wells", label: "Flowing Wells" },
  { value: "First Southern", label: "First Southern" },
  { value: "Faith Lutheran", label: "Faith Lutheran" },
  { value: "Faith Community Academy", label: "Faith Community Academy" },
  { value: "Espero Canyon", label: "Espero Canyon" },
  { value: "Esmond Station", label: "Esmond Station" },
  { value: "Empire", label: "Empire" },
  { value: "Emily Gray", label: "Emily Gray" },
  { value: "Drachman", label: "Drachman" },
  { value: "Dove Mountain", label: "Dove Mountain" },
  { value: "Doolen", label: "Doolen" },
  { value: "Dodge Traditional", label: "Dodge Traditional" },
  { value: "Dietz", label: "Dietz" },
  { value: "Desert View", label: "Desert View" },
  { value: "Desert Sky", label: "Desert Sky" },
  { value: "Desert Christian", label: "Desert Christian" },
  { value: "Cross", label: "Cross" },
  { value: "Coronado", label: "Coronado" },
  { value: "Corona Foothills", label: "Corona Foothills" },
  { value: "Civano", label: "Civano" },
  { value: "Cienega", label: "Cienega" },
  { value: "Cholla", label: "Cholla" },
  { value: "Challenger", label: "Challenger" },
  { value: "Catalina", label: "Catalina" },
  { value: "Catalina Foothills", label: "Catalina Foothills" },
  { value: "Casas", label: "Casas" },
  { value: "Canyon Del Oro", label: "Canyon Del Oro" },
  { value: "Calvary Chapel", label: "Calvary Chapel" },
  { value: "C.E. Rose", label: "C.E. Rose" },
  { value: "Borman", label: "Borman" },
  { value: "Booth-Fickett", label: "Booth-Fickett" },
  { value: "BASIS", label: "BASIS" },
  { value: "Aqua Caliente", label: "Aqua Caliente" },
  { value: "Apollo", label: "Apollo" },
  { value: "Anza Trail School", label: "Anza Trail School" },
  { value: "Andrada Polytechnic", label: "Andrada Polytechnic" },
  { value: "Amphitheater", label: "Amphitheater" },
  { value: "Altar Valley", label: "Altar Valley" },
  { value: "Alice Vail", label: "Alice Vail" },
];
