import React from "react";
import { authClasses } from "../../../pages/Auth/authClasses";

const Event = ({ evnt, setRegisterEvent, setPayEvent, registration }) => {
  const { buttonSmall } = authClasses;
  return (
    <div
      style={{
        borderRadius: "4px",
        backgroundColor: "#101010",
        borderCollapse: "collapse",
        margin: "8px",
        padding: "4px 8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <small style={{ paddingTop: "4px", color: "#bbb" }}>
        {evnt.dateString} - {evnt.location}
      </small>
      <h6 style={{ padding: "4px 0", color: "white" }}>{evnt.title}</h6>
      <small style={{ color: "#bbb", padding: "4px 0" }}>
        {evnt.description}
      </small>
      <small style={{ color: "#bbb" }}>${evnt.cost}</small>
      {evnt.open ? (
        registration === undefined ? (
          evnt.soldOut ? (
            <>
              <div
                style={{
                  backgroundColor: "red",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  margin: "8px 0",
                }}
              >
                SOLD OUT
              </div>
              Email Andrew to be added to the waitlist -
              Director@SidewinderVA.com
            </>
          ) : (
            <button
              className={buttonSmall}
              onClick={() => setRegisterEvent(evnt)}
            >
              Register Now
            </button>
          )
        ) : registration.status === "unpaid" ? (
          <button className={buttonSmall} onClick={() => setPayEvent(evnt)}>
            Pay Now
          </button>
        ) : registration.status === "pending" ? (
          <small>
            Payment Pending. Try refreshing the page in a few minutes.
          </small>
        ) : registration.status === "paid" ? (
          <small>Paid on {registration.paidAt.toLocaleString()}</small>
        ) : (
          <h6>Unclear on payment status</h6>
        )
      ) : (
        <small>Registration Opens: {evnt.openString}</small>
      )}
    </div>
  );
};

export default Event;
