import CreatableSelect from "react-select/creatable";
import React from "react";
import { SchoolOptions } from "./Options";

const SchoolSelect = ({ defaultValue, setSchool }) => {
  const handleIt = (event) => {
    setSchool(event.value);
  };
  return (
    <CreatableSelect
      defaultValue={{ value: defaultValue, label: defaultValue }}
      onChange={(event) => handleIt(event)}
      options={SchoolOptions.sort((a, b) => (a.value < b.value ? -1 : 1))}
      placeholder="Current School"
      styles={{
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: "rgb(226 232 240)",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: "rgb(51 65 85)",
          color: "rgb(226 232 240)",
          borderColor: defaultValue == null ? "var(--accent-color-1)" : "green",
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: "var(--accent-color-1)",
          backgroundColor: "rgb(51 65 85)",
          color: "rgb(226 232 240)",
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          ":hover": { backgroundColor: "rgb(81 95 115)" },
          backgroundColor: "rgb(51 65 85)",
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: "rgb(226 232 240)",
        }),
      }}
    />
  );
};

export default SchoolSelect;
