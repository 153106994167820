export const headerClasses = {
    header: "",
    navContainer: "bg-accent-color-1 border-gray-200 px-4 lg:px-6 py-2.5",
    navContent:
      "flex flex-wrap justify-between items-center mx-auto",
    linkHome:
      "ml-2 bg-black text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center",
    linkSignIn:
      "ml-2 bg-accent-color-2 text-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
    linkProfile: "w-10 h-10 p-1 rounded-full ring-2 ring-gray-300",
    avatar: "",
  };