import { DOBSelect, GradYears, Height } from "../Athletes/Options";
import React, { useState } from "react";

import FormDropdown from "../Dropdown";
import SchoolSelect from "../Athletes/SchoolSelect";

const ConfirmData = ({
  gradYear,
  setGradYear,
  school,
  setSchool,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  feet,
  setFeet,
  inches,
  setInches,
  season,
  shirtSize,
  setShirtSize,
  shortSize,
  setShortSize,
}) => {
  return (
    <div style={{ margin: "4px 0" }}>
      <h6>Confirm Athlete Data</h6>
      <div style={{ margin: "8px 0" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 2, marginRight: "8px" }}>
            <small>Current School</small>
            <SchoolSelect defaultValue={school} setSchool={setSchool} />
          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <small>HS Grad Year</small>
            <div style={{ flex: 1, display: "flex" }}>
              <FormDropdown
                options={GradYears}
                value={gradYear}
                setVal={setGradYear}
                style={
                  isNaN(parseInt(gradYear)) ? {} : { borderColor: "green" }
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "8px 0" }}>
        <small>Date of Birth</small>
        <DOBSelect
          day={day}
          setDay={setDay}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
      </div>
      <div style={{ margin: "8px 0" }}>
        <small>Height</small>
        <Height
          feet={feet}
          setFeet={setFeet}
          inches={inches}
          setInches={setInches}
        />
      </div>
      <div style={{ margin: "8px 0" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "8px" }}>
            <small>Shirt Size</small>
            <FormDropdown
              options={["Shirt Size", "S", "M", "L", "XL", "2XL"]}
              value={shirtSize}
              setVal={setShirtSize}
              style={shirtSize === "Shirt Size" ? {} : { borderColor: "green" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <small>
              {season === "Girls" ? "Spandex/Legging Size" : "Shorts Size"}
            </small>
            <FormDropdown
              options={["Shorts Size", "S", "M", "L", "XL", "2XL"]}
              value={shortSize}
              setVal={setShortSize}
              style={
                shortSize === "Shorts Size" ? {} : { borderColor: "green" }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmData;
