import { Link } from "react-router-dom";
import { headerClasses } from "./headerClasses";
import { useAppSelector } from "../../hooks/storeHook";

const Header = () => {
  const {
    header,
    navContainer,
    navContent,
    linkHome,
    linkProfile,
    linkSignIn,
  } = headerClasses;

  const { user } = useAppSelector((state) => state.auth);

  return (
    <header className={header}>
      <nav className={navContainer}>
        <div className={navContent}>
          <div className="flex items-center w-full">
            {user ? (
              <Link to="/profile">
                <div className="w-10 h-10 text-xl font-bold grid place-content-center bg-accent-color-2 rounded-full shadow-lg text-black">
                  {user?.email[0].toUpperCase()}
                </div>
              </Link>
            ) : (
              <></>
            )}
            {Boolean(!user) && (
              <Link to="/auth" className={linkSignIn}>
                Sign in
              </Link>
            )}
            <Link to="/" className={linkHome}>
              Home
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
