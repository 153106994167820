import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import Event from "./Event";
import React from "react";

const UpcomingEvents = ({ athlete, events, setRegisterEvent }) => {
  const theseEvents =
    athlete.registrations === undefined
      ? events
      : events.filter(
          (e) => athlete.registrations.find((a) => a.id === e.id) === undefined
        );
  return (
    <div
      style={{
        backgroundColor: "#1c1d21",
        borderRadius: "8px",
        marginTop: "8px",
        padding: "4px 0",
      }}
    >
      <Accordion allowMultiple>
        <AccordionItem
          header={
            <h6 style={{ margin: "8px" }}>
              Tryouts (
              {
                theseEvents.filter(
                  (e) => e.type === "Tryout" && e.hidden !== true
                ).length
              }{" "}
              Available)
            </h6>
          }
        >
          {theseEvents
            .filter((e) => e.type === "Tryout" && e.hidden !== true)
            .map((evnt) => {
              return (
                <Event
                  evnt={evnt}
                  setRegisterEvent={setRegisterEvent}
                  key={evnt.id}
                />
              );
            })}
        </AccordionItem>

        <AccordionItem
          header={
            <h6 style={{ margin: "8px" }}>
              Clinics (
              {
                theseEvents.filter(
                  (e) => e.type === "Clinic" && e.hidden !== true
                ).length
              }{" "}
              Available)
            </h6>
          }
        >
          {theseEvents
            .filter((e) => e.type === "Clinic" && e.hidden !== true)
            .map((evnt) => {
              return (
                <Event
                  evnt={evnt}
                  setRegisterEvent={setRegisterEvent}
                  key={evnt.id}
                />
              );
            })}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UpcomingEvents;
