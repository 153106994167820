import { DOBSelect, GradYears, Height } from "./Athletes/Options";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { addDoc, collection } from "firebase/firestore";

import FormDropdown from "./Dropdown";
import SchoolSelect from "./Athletes/SchoolSelect";
import { User } from "../../models/User";
import { authClasses } from "../../pages/Auth/authClasses";
import { db } from "../../firebase";

type RegisterProps = {
  user: User;
  fetchAthletes: () => {};
  setRegistering: Dispatch<SetStateAction<boolean>>;
};

const RegisterAthlete: FC<RegisterProps> = (props) => {
  const { user, fetchAthletes, setRegistering } = props;
  const { form, button, input, disabledButton } = authClasses;

  const thisYear = new Date().getFullYear();

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    console.log("Submitting");
    console.log(school);
    await addDoc(collection(db, "users", user.id, "athletes"), {
      firstName,
      lastName,
      school,
      gradYear,
      email,
      feet,
      inches,
      shirtSize,
      shortSize,
      season,
      dob: new Date(`${month} ${day}, ${year}`),
    });
    fetchAthletes();
    setRegistering(false);
  };

  const [loading, setLoading] = useState(false);

  const [season, setSeason] = useState("Season");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [month, setMonth] = useState("Month");
  const [day, setDay] = useState("Day");
  const [year, setYear] = useState("Year");
  const [school, setSchool] = useState(null);
  const [gradYear, setGradYear] = useState("Grad Year");
  const [email, setEmail] = useState("");
  const [feet, setFeet] = useState("Height (ft)");
  const [inches, setInches] = useState("Height (in)");
  const [shirtSize, setShirtSize] = useState("Shirt Size");
  const [shortSize, setShortSize] = useState("Shorts Size");

  return (
    <div className="grid" style={{ maxWidth: 400, margin: "auto" }}>
      <form onSubmit={handleFormSubmit} className={form}>
        <strong>Basic Info</strong>
        <div className="grid gap-y-5">
          <div className="grid gap-y-3">
            <input
              type="text"
              placeholder="Athlete First Name"
              className={input}
              value={firstName}
              style={firstName === "" ? {} : { borderColor: "green" }}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Athlete Last Name"
              className={input}
              value={lastName}
              style={lastName === "" ? {} : { borderColor: "green" }}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Athlete Email"
              className={input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={email === "" ? {} : { borderColor: "green" }}
              required
            />
          </div>

          <div className="grid">
            <strong>Date of Birth:</strong>
            <DOBSelect
              day={day}
              setDay={setDay}
              month={month}
              setMonth={setMonth}
              year={year}
              setYear={setYear}
            />
          </div>
          <div className="grid">
            <strong>School Info:</strong>

            <div className="grid gap-y-3">
              <SchoolSelect defaultValue={school} setSchool={setSchool} />
              <FormDropdown
                options={GradYears}
                value={gradYear}
                setVal={setGradYear}
                style={gradYear === "Grad Year" ? {} : { borderColor: "green" }}
              />

              <div>
                <strong>Height:</strong>
                <Height
                  feet={feet}
                  setFeet={setFeet}
                  inches={inches}
                  setInches={setInches}
                />
              </div>
              <strong>Season:</strong>
              <FormDropdown
                options={["Season", "Boys", "Girls"]}
                value={season}
                setVal={setSeason}
                style={season === "Season" ? {} : { borderColor: "green" }}
              />
              <strong>Shirt Size:</strong>
              <FormDropdown
                options={["Shirt Size", "S", "M", "L", "XL", "2XL"]}
                value={shirtSize}
                setVal={setShirtSize}
                style={
                  shirtSize === "Shirt Size" ? {} : { borderColor: "green" }
                }
              />
              <strong>
                {season === "Girls" ? "Spandex/Legging Size" : "Shorts Size"}
              </strong>
              <FormDropdown
                options={["Shorts Size", "S", "M", "L", "XL", "2XL"]}
                value={shortSize}
                setVal={setShortSize}
                style={
                  shortSize === "Shorts Size" ? {} : { borderColor: "green" }
                }
              />
            </div>
          </div>
          <button
            disabled={
              loading ||
              firstName === "" ||
              lastName === "" ||
              season === "Season" ||
              month === "Month" ||
              day === "Day" ||
              year === "Year" ||
              gradYear === "Grad Year" ||
              feet === "Height (ft)" ||
              inches === "Height (in)" ||
              shirtSize === "Shirt Size" ||
              shortSize === "Shorts Size"
            }
            className={
              loading ||
              firstName === "" ||
              lastName === "" ||
              season === "Season" ||
              month === "Month" ||
              day === "Day" ||
              year === "Year" ||
              gradYear === "Grad Year" ||
              feet === "Height (ft)" ||
              inches === "Height (in)" ||
              shirtSize === "Shirt Size" ||
              shortSize === "Shorts Size"
                ? disabledButton
                : button
            }
          >
            {loading ? "Saving..." : "Save Athlete"}
          </button>
        </div>
      </form>
      <button
        className={button}
        style={{
          backgroundColor: "darkred",
          margin: "1rem",
        }}
        onClick={() => setRegistering(false)}
      >
        Cancel
      </button>
    </div>
  );
};

export default RegisterAthlete;
