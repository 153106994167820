import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage"
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaRtxx5ZlNtlCVhervG4wyTlu_qYV_k18",
  authDomain: "sidewinder-3daf9.firebaseapp.com",
  projectId: "sidewinder-3daf9",
  storageBucket: "sidewinder-3daf9.appspot.com",
  messagingSenderId: "309042078832",
  appId: "1:309042078832:web:5addb5478a5f01ab2d80b5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);