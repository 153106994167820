import Dashboard from "../../components/Dashboard/Dashboard";
import Header from "../../components/Header/Header";
import { homeClasses } from "./homeClasses";
import { useAppSelector } from "../../hooks/storeHook";

const Home = () => {
  const { container, cardContainer, title, description } = homeClasses;

  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      <Header />
      <div className={container}>
        {user ? (
          <Dashboard user={user} />
        ) : (
          <div className={cardContainer}>
            <h3 className={title}>Sidewinder Parent Portal</h3>
            <p className={description}>
              This portal is for Sidewinder parents. Athletes should NOT make an
              account.
            </p>
            <br />
            <h5>Parents can use this portal to:</h5>
            <ol style={{ listStyle: "inside" }}>
              <li>Register for camps</li>
              <li>Register for tryouts</li>
              <li>Check payment status</li>
            </ol>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
