import { FC, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { User } from "../../models/User";
import { authClasses } from "../../pages/Auth/authClasses";
import { db } from "../../firebase";

type CPProps = {
  user: User;
  fetchData: () => {};
};

const CreateProfile: FC<CPProps> = (props) => {
  const { user, fetchData } = props;

  const { form, button, input } = authClasses;

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName2, setFirstName2] = useState("");
  const [lastName2, setLastname2] = useState("");
  const [phone2, setPhone2] = useState("");
  const [email2, setEmail2] = useState("");

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    await updateDoc(doc(db, "users", user.id), {
      firstName,
      lastName,
      phone,
      firstName2,
      lastName2,
      email2,
      phone2,
    });
    setLoading(false);
    fetchData();
  };

  return (
    <form onSubmit={handleFormSubmit} className={form}>
      <h6>Create Your Parent Profile</h6>
      <div className="grid gap-y-3">
        <strong>Required</strong>
        <div className="grid gap-y-3">
          <input
            type="text"
            placeholder="*First Name"
            className={input}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="*Last Name"
            className={input}
            value={lastName}
            onChange={(e) => setLastname(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="*Phone #"
            className={input}
            value={phone}
            onChange={(e) =>
              setPhone(e.target.value.slice(0, 10).replace(/\D/, ""))
            }
            pattern="[0-9]*"
            required
          />
        </div>
        <strong>Optional</strong>
        <div className="grid gap-y-3">
          <input
            type="text"
            placeholder="Guardian 2 First Name"
            className={input}
            value={firstName2}
            onChange={(e) => setFirstName2(e.target.value)}
          />
          <input
            type="text"
            placeholder="Guardian 2 Last Name"
            className={input}
            value={lastName2}
            onChange={(e) => setLastname2(e.target.value)}
          />
          <input
            type="email"
            placeholder="Guardian 2 Email"
            className={input}
            value={email2}
            onChange={(e) => setEmail2(e.target.value)}
          />
          <input
            type="tel"
            placeholder="Guardian 2 Phone #"
            className={input}
            value={phone2}
            onChange={(e) =>
              setPhone2(e.target.value.slice(0, 10).replace(/\D/, ""))
            }
            pattern="[0-9]*"
          />
        </div>
        <button disabled={loading} className={button}>
          Save Profile
        </button>
      </div>
    </form>
  );
};

export default CreateProfile;
